import { useEffect, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { checkAuth } from 'store/reducers/auth/ActionCreators';
// Общие компоненты
import Page404 from 'pages/Page404/Page404';
import RequireAuth from 'utils/RequireAuth/RequireAuth';
import withErrorSuspense from 'utils/withErrorSuspense';
import Loader from 'components/Loader/Loader';
// Локальные компоненты
import style from './RootApp.module.scss';

const Info = lazy(() => import('pages/Info'));
const RTApp = lazy(() => import('MainApp/RTApp'));

function App(): JSX.Element {
  const { isFirstAuth, user } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkAuth());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFirstAuth) {
    return <Loader />;
  }

  return (
    <div className={style.container}>
      <Routes>
        <Route element={<RequireAuth />}>
          <Route path="app/*" element={withErrorSuspense(<RTApp />)} />
        </Route>
        <Route path="blog/*" element={withErrorSuspense(<Info />)} />
        <Route path="login" element={withErrorSuspense(<Info />)} />
        {user.isAnonym ? (
          <Route path="/" element={<Navigate to="/blog" replace />} />
        ) : (
          <Route path="/" element={<Navigate to="/app" replace />} />
        )}
        <Route path="*" element={<Page404 fullScreen />} />
      </Routes>
      <ToastContainer
        position="top-right"
        transition={Slide}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
