import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TRTs, TImage, TTelemetry, TDirKTS } from 'models/CrossroadModel';
import { TImgResponse } from 'models/CrossroadResponse';
import { HOUR_1 } from 'utils/timeWork';

// TODO: Заменить во всех местах где идет работа с SO на SOCross для уточнения (Так сделано для сегментов и ктс и так нужно сделать для SO)

type TWorkNet<T> = {
  isFetching: boolean;
  isFailed: boolean;
  error: string;
  objectData: T;
};

type TWorkNetExtend<T> = {
  isInit: boolean;
} & TWorkNet<T>;

type TStatisticsObjectState = {
  data: number[][];
  wrapData: number[][];
  periodWrap: number;
};

// новый интерфейс на замену IStatisticsObjectState
// В нем нет параметра wrapData т.к. мы больше не занимаемся агрегацией данных на фронте
// В нем есть id  сущности к которой относятся данные
// Период свертки остался, т.к. он используется в отображении графиков
export type TStatisticsObjectStateNew = {
  id: string;
  data: number[][];
};

// Начальные данные для инициализациия объектов  интерфейса IStatisticsObjectStateNew
export const clearStatisticsNew: TStatisticsObjectStateNew = {
  id: '',
  data: [
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ],
};

type TRTsStatisticsObjectState = {
  SO: TWorkNet<TStatisticsObjectState>;
  KTSCross: TWorkNet<TStatisticsObjectState>;
};

type TRTsStatisticsSettingsState = {
  periodWrapSO: number;
};

export type TEventsState = {
  utime: number;
  msg: string;
};

export type TEventsDataState = {
  utime_start: number;
  utime_end: number;
  data: TEventsState[];
};

export type TReportData = {
  SOCross: TStatisticsObjectStateNew;
  KTSCross: TStatisticsObjectStateNew;
  KTSSegments: TStatisticsObjectStateNew[];
};

export type TInitDataState = {
  isInit: boolean;
  loadUTime: number;
};

export type TSetProgram = {
  times: string;
};

export type TPhases = {
  isFetching: boolean;
  error: string;
  phases: Record<number, string>;
};

type TCrossroadState = {
  initData: TWorkNet<TInitDataState>;
  image: TImage;
  rts: TRTs;
  rtsTelemetry: TWorkNet<TTelemetry>;
  rtsStatistics: TWorkNet<TRTsStatisticsObjectState>;
  rtsStatisticsSettings: TRTsStatisticsSettingsState;
  rtsGetPhases: TPhases;
  rtsEvents: TWorkNetExtend<TEventsDataState>;
  rtsDirs: TWorkNet<TDirKTS[]>;
  rtsProcessControl: TWorkNet<TSetProgram>;
  rtsReport: TWorkNet<TReportData>;
  rtsReports: TWorkNet<any>;
  rtsReportsDatas: TWorkNet<any>;
  rtsReportsAlls: TWorkNet<any>;
  rtsReportsDiff: TWorkNet<any>;
};

export const clearStatistics: TStatisticsObjectState = {
  data: [
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ],
  wrapData: [
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ],
  periodWrap: 900000,
};

const initialState: TCrossroadState = {
  initData: {
    isFetching: false,
    isFailed: false,
    error: '',
    objectData: {
      isInit: false,
      loadUTime: new Date().getTime(),
    },
  },
  image: {
    isFetching: false,
    error: '',
    source: '',
  },
  rts: {
    id: '',
    idVprocessor: null,
    dksoID: '',
    streetsName: [],
    phases: [],
    dirs: [],
    latitude: 0,
    longitude: 0,
  },
  rtsTelemetry: {
    isFetching: false,
    isFailed: false,
    error: '',
    objectData: {
      online: false,
      configuration: false,
      error: false,
      io_port: 'Нет данных',
      dk: 'Нет данных',
      control_dkso: 'Нет данных',
      control_rts: 'Нет данных',
      state_control_rts: 'Нет данных',
      phase: 0,
      phase_sequence: 'Нет данных',
      phase_durations: 'Нет данных',
      need_restart: false,
      online_time: 0,
      blink: true,
    },
  },
  rtsStatistics: {
    isFetching: false,
    isFailed: false,
    error: '',
    objectData: {
      SO: {
        isFetching: false,
        isFailed: false,
        error: '',
        objectData: {
          ...clearStatistics,
        },
      },
      KTSCross: {
        isFetching: false,
        isFailed: false,
        error: '',
        objectData: {
          ...clearStatistics,
        },
      },
    },
  },
  rtsStatisticsSettings: {
    periodWrapSO: HOUR_1,
  },
  rtsGetPhases: {
    isFetching: false,
    error: '',
    phases: {} as Record<number, string>,
  },
  rtsEvents: {
    isFetching: false,
    isFailed: false,
    error: '',
    isInit: false,
    objectData: {
      utime_start: 0,
      utime_end: 0,
      data: [],
    },
  },
  rtsDirs: {
    isFetching: false,
    isFailed: false,
    error: '',
    objectData: [],
  },
  rtsProcessControl: {
    isFetching: false,
    isFailed: false,
    error: '',
    objectData: {
      times: '',
    },
  },
  rtsReport: {
    isFetching: false,
    isFailed: false,
    error: '',
    objectData: {
      SOCross: {
        ...clearStatisticsNew,
      },
      KTSCross: {
        ...clearStatisticsNew,
      },
      KTSSegments: [],
    },
  },
  rtsReports: {
    isFetching: false,
    isFailed: false,
    error: '',
    objectData: {
      utime: 0,
    },
  },
  rtsReportsDatas: {
    isFetching: false,
    isFailed: false,
    error: '',
    objectData: {
      utime_start: 0,
      utime_end: 0,
    },
  },
  rtsReportsAlls: {
    isFetching: false,
    isFailed: false,
    error: '',
    objectData: {
      utime_start: 0,
      utime_end: 0,
    },
  },
  rtsReportsDiff: {
    isFetching: false,
    isFailed: false,
    error: '',
    objectData: {
      utime_start: 0,
      utime_end: 0,
      utime_start_two: 0,
      utime_end_two: 0,
    },
  },
};

export const crossroadSlice = createSlice({
  name: 'crossroad',
  initialState,
  reducers: {
    // Start
    crossroadFetching(state) {
      state.initData.isFetching = true;
    },
    crossroadFetchingImage(state) {
      state.image.isFetching = true;
    },
    crossroadFetchingRTsTelemetry(state) {
      state.rtsTelemetry.isFetching = true;
    },
    crossroadFetchingRTsStatistics(state) {
      state.rtsStatistics.isFetching = true;
    },
    crossroadFetchingRTsStatisticsSO(state) {
      state.rtsStatistics.objectData.SO.isFetching = true;
    },
    crossroadFetchingRTsStatisticsKTSCross(state) {
      state.rtsStatistics.objectData.KTSCross.isFetching = true;
    },
    crossroadFetchingRTsStatisticsKTSSegmentsLastHour(state) {
      state.rtsDirs.isFetching = true;
    },
    crossroadFetchingRTsEvents(state) {
      state.rtsEvents.isFetching = true;
    },
    crossroadFetchingRTsProcessControl(state) {
      state.rtsProcessControl.isFetching = true;
    },
    crossroadFetchingRTsReport(state) {
      state.rtsReport.isFetching = true;
    },
    crossroadFetchingRTsReports(state) {
      state.rtsReports.isFetching = true;
    },
    crossroadFetchingRTsReportsDatas(state) {
      state.rtsReportsDatas.isFetching = true;
    },
    crossroadFetchingRTsReportsAlls(state) {
      state.rtsReportsAlls.isFetching = true;
    },
    crossroadFetchingRTsReportsDiff(state) {
      state.rtsReportsDiff.isFetching = true;
    },
    // Init
    crossroadFetchingRTsEventsInit(state) {
      state.rtsEvents.isInit = true;
    },
    crossroadFetchingRTsReportsInit(state) {
      state.rtsReports.isFetching = true;
    },
    crossroadFetchingRTsReportsDatasInit(state) {
      state.rtsReportsDatas.isFetching = true;
    },
    crossroadFetchingRTsReportsAllsInit(state) {
      state.rtsReportsAlls.isFetching = true;
    },
    crossroadFetchingRTsReportsDiffInit(state) {
      state.rtsReportsDiff.isFetching = true;
    },
    crossroadFetchingRtsGetPhaseInit(state) {
      state.rtsGetPhases.isFetching = true;
    },
    // Success
    rtsFetchingDetailSuccess(state, action: PayloadAction<TRTs>) {
      state.initData.objectData.isInit = true;
      state.initData.isFetching = false;
      state.initData.isFailed = false;
      state.initData.error = '';
      state.rts = action.payload;
    },
    crossroadFetchingImageSuccess(state, action: PayloadAction<TImgResponse>) {
      state.image = {
        isFetching: false,
        error: '',
        source: action.payload.img,
      };
    },
    crossroadFetchingRTsTelemetrySuccess(state, action: PayloadAction<TTelemetry>) {
      state.rtsTelemetry = {
        isFetching: false,
        isFailed: false,
        error: '',
        objectData: action.payload,
      };
    },
    crossroadFetchingRTsStatisticsSuccess(state) {
      state.rtsStatistics.isFetching = false;
      state.rtsStatistics.isFailed = false;
      state.rtsStatistics.error = '';
    },
    crossroadFetchingRTsStatisticsSOSuccess(state, action: PayloadAction<TStatisticsObjectState>) {
      state.rtsStatistics.objectData.SO.isFetching = false;
      state.rtsStatistics.objectData.SO.isFailed = false;
      state.rtsStatistics.objectData.SO.error = '';
      state.rtsStatistics.objectData.SO.objectData = action.payload;
    },
    crossroadFetchingRTsStatisticsKTSCrossSuccess(state, action: PayloadAction<TStatisticsObjectState>) {
      state.rtsStatistics.objectData.KTSCross.isFetching = false;
      state.rtsStatistics.objectData.KTSCross.isFailed = false;
      state.rtsStatistics.objectData.KTSCross.error = '';
      state.rtsStatistics.objectData.KTSCross.objectData = action.payload;
    },
    crossroadFetchingRTsStatisticsKTSSegmentsLastHourSuccess(state, action: PayloadAction<TDirKTS[]>) {
      state.rtsDirs.isFetching = false;
      state.rtsDirs.isFailed = false;
      state.rtsDirs.error = '';
      state.rtsDirs.objectData = action.payload;
    },
    crossroadFetchingRTsEventsSuccess(state, action: PayloadAction<TEventsDataState>) {
      state.rtsEvents.isFetching = false;
      state.rtsEvents.isFailed = false;
      state.rtsEvents.error = '';
      state.rtsEvents.objectData.utime_start = action.payload.utime_start;
      state.rtsEvents.objectData.utime_end = action.payload.utime_end;
      state.rtsEvents.objectData.data = state.rtsEvents.objectData.data.concat(action.payload.data);
    },
    crossroadFetchingRTsProcessControlSuccess(state, action: PayloadAction<TSetProgram>) {
      state.rtsProcessControl.isFetching = false;
      state.rtsProcessControl.isFailed = false;
      state.rtsProcessControl.error = '';
      state.rtsProcessControl.objectData.times = action.payload.times;
    },
    crossroadFetchingRTsReportSuccess(state, action: PayloadAction<TReportData>) {
      state.rtsReport.isFetching = false;
      state.rtsReport.isFailed = false;
      state.rtsReport.error = '';
      state.rtsReport.objectData = action.payload;
    },
    crossroadFetchingRTsReportsSuccess(state, action: PayloadAction<string>) {
      state.rtsReports.isFetching = false;
      state.rtsReports.isFailed = false;
      state.rtsReports.error = '';
      state.rtsReports.objectData = action.payload;
    },
    crossroadFetchingRTsReportsDatasSuccess(state, action: PayloadAction<string>) {
      state.rtsReportsDatas.isFetching = false;
      state.rtsReportsDatas.isFailed = false;
      state.rtsReportsDatas.error = '';
      state.rtsReportsDatas.objectData = action.payload;
    },
    crossroadFetchingRTsReportsAllsSuccess(state, action: PayloadAction<string>) {
      state.rtsReportsAlls.isFetching = false;
      state.rtsReportsAlls.isFailed = false;
      state.rtsReportsAlls.error = '';
      state.rtsReportsAlls.objectData = action.payload;
    },
    crossroadFetchingRTsReportsDiffSuccess(state, action: PayloadAction<string>) {
      state.rtsReportsDiff.isFetching = false;
      state.rtsReportsDiff.isFailed = false;
      state.rtsReportsDiff.error = '';
      state.rtsReportsDiff.objectData = action.payload;
    },
    crossroadFetchingRtsGetPhasesSuccess(state, action: PayloadAction<Record<number, string>>) {
      state.rtsGetPhases.isFetching = false;
      state.rtsGetPhases.error = '';
      state.rtsGetPhases.phases = action.payload;
    },
    // Error
    crossroadFetchingError(state, action: PayloadAction<string>) {
      state.initData.objectData.isInit = true;
      state.initData.isFetching = false;
      state.initData.isFailed = true;
      state.initData.error = action.payload;
    },
    crossroadFetchingImageError(state, action: PayloadAction<string>) {
      state.image.isFetching = false;
      state.image.error = action.payload;
      state.image.source = '';
    },
    crossroadFetchingRTsTelemetryError(state, action: PayloadAction<string>) {
      state.rtsTelemetry.isFetching = false;
      state.rtsTelemetry.isFailed = true;
      state.rtsTelemetry.error = action.payload;
    },
    crossroadFetchingRTsStatisticsError(state, action: PayloadAction<string>) {
      state.rtsStatistics.isFetching = false;
      state.rtsStatistics.isFailed = true;
      state.rtsStatistics.error = action.payload;
    },
    crossroadFetchingRTsStatisticsSOError(state, action: PayloadAction<string>) {
      state.rtsStatistics.objectData.SO.isFetching = false;
      state.rtsStatistics.objectData.SO.isFailed = true;
      state.rtsStatistics.objectData.SO.error = action.payload;
    },
    crossroadFetchingRTsStatisticsKTSCrossError(state, action: PayloadAction<string>) {
      state.rtsStatistics.objectData.KTSCross.isFetching = false;
      state.rtsStatistics.objectData.KTSCross.isFailed = true;
      state.rtsStatistics.objectData.KTSCross.error = action.payload;
    },
    crossroadFetchingRTsStatisticsKTSSegmentsLastHourError(state, action: PayloadAction<string>) {
      state.rtsDirs.isFetching = false;
      state.rtsDirs.isFailed = true;
      state.rtsDirs.error = action.payload;
    },
    crossroadFetchingRTsEventsError(state, action: PayloadAction<string>) {
      state.rtsEvents.isFetching = false;
      state.rtsEvents.isFailed = true;
      state.rtsEvents.error = action.payload;
    },
    crossroadFetchingRTsProcessControlError(state, action: PayloadAction<string>) {
      state.rtsProcessControl.isFetching = false;
      state.rtsProcessControl.isFailed = true;
      state.rtsProcessControl.error = action.payload;
    },
    crossroadFetchingRTsReportError(state, action: PayloadAction<string>) {
      state.rtsReport.isFetching = false;
      state.rtsReport.isFailed = true;
      state.rtsReport.error = action.payload;
    },
    crossroadFetchingRTsReportsError(state, action: PayloadAction<string>) {
      state.rtsReports.isFetching = false;
      state.rtsReports.isFailed = true;
      state.rtsReports.error = action.payload;
    },
    crossroadFetchingRTsReportsDatasError(state, action: PayloadAction<string>) {
      state.rtsReportsDatas.isFetching = false;
      state.rtsReportsDatas.isFailed = true;
      state.rtsReportsDatas.error = action.payload;
    },
    crossroadFetchingRTsReportsAllsError(state, action: PayloadAction<string>) {
      state.rtsReportsAlls.isFetching = false;
      state.rtsReportsAlls.isFailed = true;
      state.rtsReportsAlls.error = action.payload;
    },
    crossroadFetchingRTsReportsDiffError(state, action: PayloadAction<string>) {
      state.rtsReportsDiff.isFetching = false;
      state.rtsReportsDiff.isFailed = true;
      state.rtsReportsDiff.error = action.payload;
    },
    crossroadFetchingRtsGetPhasesError(state, action: PayloadAction<string>) {
      state.rtsGetPhases.isFetching = false;
      state.rtsGetPhases.error = action.payload;
    },
    // reset state
    rtsReset() {
      return initialState;
    },
    resetEventsData(state) {
      state.rtsEvents.objectData.data = [];
    },
  },
});

export default crossroadSlice.reducer;
