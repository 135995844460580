import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/auth/AuthSlice';
import userReducer from './reducers/user/UserSlice';
import cityReducer from './reducers/city/CitySlice';
import mapReducer from './reducers/map/MapSlice';
import crossroadReducer from './reducers/crossroad/CrossroadSlice';
import archiveReducer from './reducers/archive/ArchiveSlice';
import udsReducer from './reducers/uds/UdsSlice';
import starReducer from './reducers/starState/StartSlice';
import themeReducer from './reducers/themeState/ThemeSlice';
import JournalStateReducer from './reducers/journal/JournalSlice';
import NewReportsStateReducer from './reducers/newReportsState/NewReportsSlice';

const rootReducer = combineReducers({
  authReducer,
  userReducer,
  cityReducer,
  mapReducer,
  crossroadReducer,
  archiveReducer,
  udsReducer,
  starReducer,
  themeReducer,
  JournalStateReducer,
  NewReportsStateReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(postAPI.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
const store = setupStore();
export default store;
