import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TModelArchive, TDescript } from 'models/ArchiveResponse';

type SectionArchive = {
  podd: boolean;
  reports: boolean;
  projects: boolean;
  tech: boolean;
  letters: boolean;
  journal: boolean;
};

type TWorkNet<T> = {
  isInit: boolean;
  isFetching: boolean;
  error: string;
  objectData: T;
};

const today = new Date();
const date = today.setDate(today.getDate());
const defaultValue = new Date(date).toISOString().split('T')[0]; // yyyy-mm-dd

type IArchiveState = {
  archiveGet: TWorkNet<TModelArchive>;
  archivePost: TWorkNet<TDescript>;
  archivePutComment: TWorkNet<TDescript>;
  archiveSection: TWorkNet<SectionArchive>;
};

const initialState: IArchiveState = {
  archiveGet: {
    isInit: false,
    isFetching: false,
    error: '',
    objectData: {
      data: [],
    },
  },
  archivePost: {
    isInit: true,
    isFetching: false,
    error: '',
    objectData: {
      name_object: '',
      target_object: '',
      file_description: '',
      file_name: '',
      time: defaultValue,
      comment: '',
    },
  },
  archivePutComment: {
    isInit: true,
    isFetching: false,
    error: '',
    objectData: {
      name_object: '',
      target_object: '',
      file_description: '',
      file_name: '',
      time: defaultValue,
      comment: '',
    },
  },
  archiveSection: {
    isInit: true,
    isFetching: false,
    error: '',
    objectData: {
      podd: false,
      reports: false,
      projects: false,
      tech: false,
      letters: false,
      journal: false,
    },
  },
};

export const archiveSlice = createSlice({
  name: 'archive',
  initialState,
  reducers: {
    // Start
    archiveFetchingGet(state) {
      state.archiveGet.isFetching = true;
    },
    archiveFetchingPost(state) {
      state.archivePost.isFetching = true;
    },
    archiveFetchingPutComment(state) {
      state.archivePutComment.isFetching = true;
    },
    archiveFetchingSection(state) {
      state.archiveSection.isFetching = true;
    },
    // Success
    archiveFetchingGetSuccess(state, action: PayloadAction<TModelArchive>) {
      state.archiveGet.isFetching = false;
      state.archiveGet.objectData.data = action.payload.data;
      state.archiveGet.error = '';
    },
    archiveFetchingPostSuccess(state, action: PayloadAction<TDescript>) {
      state.archivePost = {
        isInit: true,
        isFetching: false,
        error: '',
        objectData: action.payload,
      };
    },
    archiveFetchingPutCommentSuccess(state, action: PayloadAction<TDescript>) {
      state.archivePutComment = {
        isInit: true,
        isFetching: false,
        error: '',
        objectData: action.payload,
      };
    },
    archiveFetchingSectionSuccess(state, action: PayloadAction<SectionArchive>) {
      state.archiveSection = {
        isInit: true,
        isFetching: false,
        error: '',
        objectData: action.payload,
      };
    },
    // Error
    archiveFetchingGetError(state, action: PayloadAction<string>) {
      state.archiveGet.isFetching = false;
      state.archiveGet.error = action.payload;
    },
    archiveFetchingPostError(state, action: PayloadAction<string>) {
      state.archivePost.isInit = true;
      state.archivePost.isFetching = false;
      state.archivePost.error = action.payload;
    },
    archiveFetchingPutCommentError(state, action: PayloadAction<string>) {
      state.archivePutComment.isInit = true;
      state.archivePutComment.isFetching = false;
      state.archivePutComment.error = action.payload;
    },
    archiveFetchingSectionError(state, action: PayloadAction<string>) {
      state.archiveSection.isInit = true;
      state.archiveSection.isFetching = false;
      state.archiveSection.error = action.payload;
    },
    // reset state
    archiveReset() {
      return initialState;
    },
  },
});

export default archiveSlice.reducer;
