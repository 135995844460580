import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IStar = {
  star: boolean;
  station: boolean;
  bus: boolean;
  spec: boolean;
  road: boolean;
  auto: boolean;
  sun: boolean;
};

const initialState: IStar = {
  star: true,
  station: true,
  bus: true,
  spec: true,
  road: true,
  auto: true,
  sun: false,
};

export const starSlice = createSlice({
  name: 'star',
  initialState,
  reducers: {
    starState(state, action: PayloadAction<boolean>) {
      state.star = action.payload;
    },
    stationState(state, action: PayloadAction<boolean>) {
      state.station = action.payload;
    },
    busState(state, action: PayloadAction<boolean>) {
      state.bus = action.payload;
    },
    specState(state, action: PayloadAction<boolean>) {
      state.spec = action.payload;
    },
    roadState(state, action: PayloadAction<boolean>) {
      state.road = action.payload;
    },
    autoState(state, action: PayloadAction<boolean>) {
      state.auto = action.payload;
    },
    sunState(state, action: PayloadAction<boolean>) {
      state.sun = action.payload;
    },
  },
});

export default starSlice.reducer;
