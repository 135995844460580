import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Ijournal = {
  start: number;
  end: number;
};

const initialState: Ijournal = {
  start: new Date(new Date().setHours(0, 0, 1)).getTime(),
  end: new Date(new Date().setHours(23, 59, 59)).getTime(),
};

export const JournalStateSlice = createSlice({
  name: 'journalState',
  initialState,
  reducers: {
    startState(state, action: PayloadAction<number>) {
      state.start = action.payload;
    },
    endState(state, action: PayloadAction<number>) {
      state.end = action.payload;
    },
  },
});

export default JournalStateSlice.reducer;
