import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCityResponse } from 'models/CityResponse';

type TCityState = {
  isInit: boolean;
  isFetching: boolean;
  error: string;
  name: string;
  term: string;
  weather: string;
  initUtime: number;
  offsetFromLocal: number;
  offsetUtimeTz: number;
  delayStartUpdateStatisticsMillisecond: number;
  latitude: number;
  longitude: number;
  isTest: boolean;
};

const initialState: TCityState = {
  isInit: false,
  isFetching: false,
  error: '',
  name: '',
  term: '',
  weather: '',
  initUtime: 0,
  offsetFromLocal: 0,
  offsetUtimeTz: 0,
  delayStartUpdateStatisticsMillisecond: 0,
  latitude: 0,
  longitude: 0,
  isTest: true,
};

export const citySlice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    // Start
    cityFetching(state) {
      state.isFetching = true;
    },
    // Success
    cityFetchingSuccess(state, action: PayloadAction<TCityResponse>) {
      state.isInit = true;
      state.isFetching = false;
      state.error = '';
      state.name = action.payload.name;
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
      state.initUtime = action.payload.current_utime * 1000;
      state.offsetUtimeTz = action.payload.offset_utime_tz * 1000;
      state.offsetFromLocal = (new Date().getTimezoneOffset() * 60 + action.payload.offset_utime_tz) * 1000;
      state.isTest = action.payload.is_test;
    },
    // Error
    cityFetchingError(state, action: PayloadAction<string>) {
      state.isInit = true;
      state.isFetching = false;
      state.error = action.payload;
    },
    // reset state
    cityReset() {
      return initialState;
    },
  },
});

export default citySlice.reducer;
