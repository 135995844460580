import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Loader from 'components/Loader/Loader';

function ErrorFallback() {
  return (
    <div>
      <p>Не удалось загрузить страницу. Попробуйте перезагрузиться.</p>
    </div>
  );
}

function withErrorSuspense(Component: JSX.Element): JSX.Element {
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <Suspense fallback={<Loader />}>{Component}</Suspense>
    </ErrorBoundary>
  );
}

export default withErrorSuspense;
