import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import style from './Page404.module.scss';

interface IProps {
  fullScreen?: boolean;
}

const defaultProps = {
  fullScreen: false,
};

function Page404({ fullScreen }: IProps): JSX.Element {
  useEffect(() => {
    document.title = 'DELS-RT.web: Error';
    return () => {
      document.title = 'DELS-RT.web';
    };
  }, []);
  return (
    <div className={`${style.wrapper} ${fullScreen && style.fullScreen}`}>
      <div className={style.container}>
        <h1 className={style.title}>Страница не найдена. Ошибка 404.</h1>
        <p>
          <NavLink to="/" className={style.backLink}>
            Вернуться на главную
          </NavLink>
        </p>
      </div>
    </div>
  );
}

Page404.defaultProps = defaultProps;

export default Page404;
