// Общие компоненты
import Spinner from './Spinner';
// Локальные компоненты
import style from './Loader.module.scss';

function Loader(): JSX.Element {
  return (
    <div className={style.container}>
      <Spinner />
    </div>
  );
}

export default Loader;
