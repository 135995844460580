import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TFio, TPhone, TAvatar } from 'models/UserResponse';

type TUserState = {
  isInit: boolean;
  isFetching: boolean;
  error: string;
  userFio: TFio;
  userPhone: TPhone;
  userAvatar: TAvatar;
};

const initialState: TUserState = {
  isInit: false,
  isFetching: false,
  error: '',
  userFio: { fio: '' },
  userPhone: { phone: '' },
  userAvatar: { file_name: '' },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // start
    userFetching(state) {
      state.isFetching = true;
    },
    // success
    userFetchingSuccessFio(state, action: PayloadAction<TFio>) {
      state.isFetching = false;
      state.error = '';
      state.userFio = action.payload;
    },
    userFetchingSuccessPhone(state, action: PayloadAction<TPhone>) {
      state.isFetching = false;
      state.error = '';
      state.userPhone = action.payload;
    },
    userFetchingSuccessAvatar(state, action: PayloadAction<TAvatar>) {
      state.isFetching = false;
      state.error = '';
      state.userAvatar = action.payload;
    },
    // error
    userFetchingError(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    // reset state
    userReset() {
      return initialState;
    },
  },
});

export default userSlice.reducer;
