import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCityItemsResponse, TFeatureCollection } from 'models/CityResponse';
import { TItemMap } from 'models/MapResponse';

type TMapState = {
  isFetching: boolean;
  error: string;
  rts: Array<TItemMap>;
  rtsOn: boolean;
  transport: TFeatureCollection;
};

const initialState: TMapState = {
  isFetching: false,
  error: '',
  rts: [],
  rtsOn: true,
  transport: {
    type: 'FeatureCollection',
    features: [],
  },
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    // Start
    mapFetching(state) {
      state.isFetching = true;
    },
    // Success
    mapFetchingSuccess(state, action: PayloadAction<TCityItemsResponse<TItemMap>>) {
      state.isFetching = false;
      state.error = '';
      state.rts = action.payload.rts;
    },
    rtsState(state, action: PayloadAction<boolean>) {
      state.rtsOn = action.payload;
    },
    mapFetchingSuccessTransport(state, action: PayloadAction<TFeatureCollection>) {
      state.isFetching = false;
      state.error = '';
      state.transport = action.payload;
    },
    // Error
    mapFetchingError(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    // reset state
    mapReset() {
      return initialState;
    },
  },
});

export default mapSlice.reducer;
