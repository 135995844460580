import { createSlice } from '@reduxjs/toolkit';

type ITheme = {
  theme: boolean;
};

const savedTheme = localStorage.getItem('theme'); // Проверяем, есть ли сохраненное значение в localStorage
const initialState: ITheme = {
  theme: savedTheme ? JSON.parse(savedTheme) : true, // Используем сохраненное значение, если оно есть
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    themeState(state) {
      state.theme = !state.theme;
      localStorage.setItem('theme', JSON.stringify(state.theme)); // Сохраняем значение в localStorage
    },
  },
});

export default themeSlice.reducer;
