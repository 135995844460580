export type TUser = {
  id: string;
  fio: string;
  phone: string;
  email: string;
  access: boolean;
  isAnonym?: boolean;
};

export const anonym: TUser = {
  id: 'anonym',
  fio: 'anonym',
  phone: '',
  email: '',
  access: true,
  isAnonym: true,
};

export type TAuthResponse = {
  access_token: string;
  user: TUser;
};
