import style from './Spinner.module.scss';
import { ReactComponent as Load } from './load.svg';
import { ReactComponent as Loads } from './loads.svg';

function Spinner(): JSX.Element {
  return (
    <div className={style.back}>
      <div className={style.loaders}>
        <Load />
      </div>
      <div className={style.loaderss}>
        <Loads />
      </div>
    </div>
  );
}

export default Spinner;
