import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TModelUds, TUdss } from 'models/UdsResponse';
import { TModelComment, TComments } from 'models/CommentResponse';

type TWorkNet<T> = {
  isInit: boolean;
  isFetching: boolean;
  error: string;
  objectData: T;
};

type TUdsState = {
  udsGet: TWorkNet<TModelUds>;
  udsPost: TWorkNet<TUdss>;
  udsPut: TWorkNet<TUdss>;
  udsGetComment: TWorkNet<TModelComment>;
  udsPostComment: TWorkNet<TComments>;
};

const initialState: TUdsState = {
  udsGet: {
    isInit: false,
    isFetching: false,
    error: '',
    objectData: {
      data: [],
    },
  },
  udsPost: {
    isInit: true,
    isFetching: false,
    error: '',
    objectData: {
      author: '',
      themes: '',
      executor: '',
      description: '',
      latitude: 0,
      longitude: 0,
      create_time: '',
      work_time: '',
      task_time: '',
      do_time: '',
      close_time: '',
    },
  },
  udsPut: {
    isInit: true,
    isFetching: false,
    error: '',
    objectData: {
      author: '',
      themes: '',
      executor: '',
      description: '',
      latitude: 0,
      longitude: 0,
      create_time: '',
      work_time: '',
      task_time: '',
      do_time: '',
      close_time: '',
    },
  },
  udsGetComment: {
    isInit: false,
    isFetching: false,
    error: '',
    objectData: {
      data: [],
    },
  },
  udsPostComment: {
    isInit: false,
    isFetching: false,
    error: '',
    objectData: {
      author: '',
      description: '',
      times: '',
    },
  },
};

export const udsSlice = createSlice({
  name: 'uds',
  initialState,
  reducers: {
    // Start
    udsFetchingGet(state) {
      state.udsGet.isFetching = true;
    },
    udsFetchingPost(state) {
      state.udsPost.isFetching = true;
    },
    udsFetchingPut(state) {
      state.udsPut.isFetching = true;
    },
    udsFetchingGetComment(state) {
      state.udsGetComment.isFetching = true;
    },
    udsFetchingPostComment(state) {
      state.udsPostComment.isFetching = true;
    },
    // Success
    udsGetFetchingSuccess(state, action: PayloadAction<TModelUds>) {
      state.udsGet.isFetching = false;
      state.udsGet.objectData.data = action.payload.data;
      state.udsGet.error = '';
    },
    udsPostFetchingSuccess(state, action: PayloadAction<TUdss>) {
      state.udsPost = {
        isInit: true,
        isFetching: false,
        error: '',
        objectData: action.payload,
      };
    },
    udsPutFetchingSuccess(state, action: PayloadAction<TUdss>) {
      state.udsPut = {
        isInit: true,
        isFetching: false,
        error: '',
        objectData: action.payload,
      };
    },
    udsGetCommentFetchingSuccess(state, action: PayloadAction<TModelComment>) {
      state.udsGetComment.isFetching = false;
      state.udsGetComment.objectData.data = action.payload.data;
      state.udsGetComment.error = '';
    },
    udsPostCommentFetchingSuccess(state, action: PayloadAction<TComments>) {
      state.udsPostComment = {
        isInit: true,
        isFetching: false,
        error: '',
        objectData: action.payload,
      };
    },
    // Error
    udsGetFetchingError(state, action: PayloadAction<string>) {
      state.udsGet.isFetching = false;
      state.udsGet.error = action.payload;
    },
    udsPostFetchingError(state, action: PayloadAction<string>) {
      state.udsPost.isInit = true;
      state.udsPost.isFetching = false;
      state.udsPost.error = action.payload;
    },
    udsPutFetchingError(state, action: PayloadAction<string>) {
      state.udsPut.isInit = true;
      state.udsPut.isFetching = false;
      state.udsPut.error = action.payload;
    },
    udsGetCommentFetchingError(state, action: PayloadAction<string>) {
      state.udsGetComment.isFetching = false;
      state.udsGetComment.error = action.payload;
    },
    udsPostCommentFetchingError(state, action: PayloadAction<string>) {
      state.udsPostComment.isInit = true;
      state.udsPostComment.isFetching = false;
      state.udsPostComment.error = action.payload;
    },
    // reset state
    udsReset() {
      return initialState;
    },
  },
});

export default udsSlice.reducer;
