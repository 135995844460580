import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InewReports = {
  balance: boolean;
  todays: boolean;
  alls: boolean;
  datas: boolean;
  inputOne: boolean;
  inputTwo: boolean;
  inputThree: boolean;
  inputFour: boolean;
  inputFive: boolean;
  loader: boolean;
};

const initialState: InewReports = {
  balance: false,
  todays: true,
  alls: false,
  datas: false,
  inputOne: true,
  inputTwo: false,
  inputThree: false,
  inputFour: false,
  inputFive: false,
  loader: false,
};

export const NewReportsStateSlice = createSlice({
  name: 'reportsState',
  initialState,
  reducers: {
    balanceState(state, action: PayloadAction<boolean>) {
      state.balance = action.payload;
    },
    todaysState(state, action: PayloadAction<boolean>) {
      state.todays = action.payload;
    },
    allsState(state, action: PayloadAction<boolean>) {
      state.alls = action.payload;
    },
    datasState(state, action: PayloadAction<boolean>) {
      state.datas = action.payload;
    },
    inputOneState(state, action: PayloadAction<boolean>) {
      state.inputOne = action.payload;
    },
    inputTwoState(state, action: PayloadAction<boolean>) {
      state.inputTwo = action.payload;
    },
    inputThreeState(state, action: PayloadAction<boolean>) {
      state.inputThree = action.payload;
    },
    inputFourState(state, action: PayloadAction<boolean>) {
      state.inputFour = action.payload;
    },
    inputFiveState(state, action: PayloadAction<boolean>) {
      state.inputFive = action.payload;
    },
    inputLoaderState(state, action: PayloadAction<boolean>) {
      state.loader = action.payload;
    },
  },
});

export default NewReportsStateSlice.reducer;
